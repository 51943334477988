.table {
  .actions {
    i {
      padding-top: 7px;
      padding-right: 7px;
      font-size: 22px;
      cursor: pointer;
    }
  }
}
