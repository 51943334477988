.main-app{
    display: flex;
    .right-container{
        width: 100%;
    }
}
.loading-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}
.App-logo {
    height: 10vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    font-size: calc(10px + 2vmin);
    color: white;
}
  
.App-link {
    color: #61dafb;
}