.avatar-benhnhan {
  max-width: 100%;
}
.anticon {
  vertical-align: 0.1em !important;
}
.container-fluid {
  .table {
    .table-striped {
      .tr-table {
        .actions-container {
          .item-container {
            text-align: center;
            display: flex;
            align-items: center !important;
            .item {
              flex-basis: 33.33%;
              flex: 1 1 150px;
              i {
                font-size: 25px;
                cursor: pointer;
              }
            }
          }
          @media screen and (max-width: 768px) {
            .item-container {
              display: flex;
              flex-wrap: wrap;
              .item {
                flex: 1 1 150px;
                flex-basis: 50%;
              }
            }
          }
          @media screen and (max-width: 1200px) {
            .item-container {
              display: flex;
              flex-wrap: wrap;
              .item {
                flex: 1 1 150px;
                flex-basis: 25%;
              }
            }
          }
        }
      }
    }
  }
  .search-content {
    // .input-search{
    //     margin-right: 1px;
    // }
    .button-search {
      margin-left: 10px;
      align-items: center;
    }
  }
  .link-name {
    background: none;
    border: none;
    color: blue;
    text-decoration: none;
    cursor: pointer;
  }
  .expandContent {
    justify-content: center;
    justify-items: center;
    align-items: center;
    .expandContentDetail {
      width: 100%;
    }
    .avatar {
      max-width: 100%;
    }
    .upContent {
      align-items: center;
      font-weight: bold;
    }
    .tabsContent {
      button {
        &:hover {
          text-decoration: none;
          cursor: pointer;
        }
        &:active {
          text-decoration: none;
        }
      }
      // .tab-content{
      //     .textarea{
      //         font-size: x-large;
      //         font-weight: bold;
      //         resize: both;
      //         pointer-events: none;
      //     }
      // }
    }
  }
}
