.sidebar-content {
  display: flex;
  height: 100vh;

  .icon {
    margin-right: 15px;
    margin-left: 7px;
    margin-bottom: 3px;
  }
}
.showhideArrow {
  //border: 1px solid lightgreen;
  cursor: pointer;
  height: 50px;
  width: 35px;
  background-color: #f8f9fa;
  .arrowIcon {
    height: 50px;
  }
}
