.home-container {
  background-image: url(../../assets/images/wintery-sunburst.svg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .title1 {
    padding-top: 5rem;
    text-align: center;
    height: 33.33%;
    display: inline-block;
    width: 100%;
    font-size: 73px;
    color: red;
    text-shadow: #fffca8 2px 2px 0px, #9c9c9c 4px 4px 0px;
    color: red;
    font-weight: 600;
  }
  .title-mobi {
    padding-top: 5rem;
    text-align: center;
    height: 33.33%;
    display: inline-block;
    width: 100%;
    font-size: 30px;
    color: red;
    text-shadow: #fffca8 2px 2px 0px, #9c9c9c 4px 4px 0px;
    color: red;
    font-weight: 600;
  }
  .logo {
    margin-bottom: 2rem;
    height: 33.33%;
    color: #61dafb !important;
    img {
      animation: App-logo-spin infinite 20s linear;
    }
    @keyframes App-logo-spin {
      from {
        transform: rotate(360deg);
      }
      to {
        transform: rotate(0deg);
      }
    }
  }
  .logo-mobi {
    height: 33.33%;
    color: #61dafb !important;
    img {
      animation: App-logo-spin infinite 20s linear;
    }
    @keyframes App-logo-spin {
      from {
        transform: rotate(360deg);
      }
      to {
        transform: rotate(0deg);
      }
    }
  }
  .title2 {
    padding-top: 1rem;
    text-align: center;
    height: 33.33%;
    width: 100%;
    .sub-title1 {
      font-weight: 700;
      font-size: 30px;
      color: rgb(20, 187, 78);
      text-shadow: -1px -1px 1px rgba(255, 255, 255, 0.1),
        1px 1px 1px rgba(0, 0, 0, 0.5);
    }
    .sub-title1-mobi {
      font-weight: 700;
      font-size: 15px;
      color: rgb(20, 187, 78);
      text-shadow: -1px -1px 1px rgba(255, 255, 255, 0.1),
        1px 1px 1px rgba(0, 0, 0, 0.5);
    }
    .sub-title2 {
      font-size: 130px;
      font-weight: 700;
      color: rgb(255, 192, 0);
      text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #49ff18,
        0 0 30px #49ff18, 0 0 40px #49ff18, 0 0 55px #49ff18, 0 0 75px #49ff18;
    }
    .sub-title2-mobi {
      font-size: 50px;
      font-weight: 500;
      color: rgb(255, 192, 0);
      text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #49ff18,
        0 0 30px #49ff18, 0 0 40px #49ff18, 0 0 55px #49ff18, 0 0 75px #49ff18;
    }
  }
}
