.bt-content {
  margin-right: 35px;
}
.Container-home-upload {
  .ant-table-thead .ant-table-cell {
    background-color: #cfe2ff;
    font-size: 15px;
    font-weight: 700;
  }
  .table-container {
    margin-right: 35px;
  }
}
.hr {
  margin-right: 35px;
}
.anticon {
  vertical-align: 0.1em !important;
}
